import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./DashBoard.css";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { GetTabs, CreateTab, UpdateTab } from "./store/actions/tabsActions";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import { BeatLoader } from "react-spinners";
import toast from "react-hot-toast";
import profileImage from "./images/user_photo.png";
import { TbEdit } from "react-icons/tb";
import { AddDetailModalComp } from "./AddDetailModal";

const DashBoard = () => {
  const [detailModal, setDetailModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { tabs } = useSelector((state) => state.tab);
  const { user, company } = useSelector((state) => state.user);
  const [selectedTab, setSelectedTab] = useState(null);
  useEffect(() => {
    let URLToken = window.location.href.split("dashboard/")[1]?.split("&")[0];
    console.log("URLToken", URLToken);
    if (URLToken) {
      if (URLToken !== localStorage.getItem("token")) {
        localStorage.setItem("token", URLToken);
        window.location.reload();
      }
    }
    const token = URLToken || localStorage.getItem("token");

    if (token && !company && !user) {
      dispatch({ type: "LOAD_USER_REQUEST" }); // Trigger the saga to fetch user data
    } else {
      if (user?.accountType !== "admin") {
        navigate("/forbidden");
      }
    }
  }, [dispatch, company, user]);

  const navigateToDetails = () => {
    navigate("/risk-table");
  };

  useEffect(() => {
    if (company && !tabs) {
      dispatch(GetTabs(company?._id, "kyc"));
    }
  }, [company, tabs]);
  const editdata = (item, index) => {
    setSelectedTab(item);
    setDetailModal(true);
  };

  return (
    <>
      <div className="risk-profile-btn" onClick={navigateToDetails}>
        <img width={20} src={profileImage} />{" "}
        <span className="risk-profile-txt"> Risk Profile</span>{" "}
      </div>
      <div className="button-container">
        <span className="risk-factors-heading">Risk Factors</span>

        <Button
          className="top-right-button"
          onClick={() => {
            setSelectedTab(null);
            setDetailModal(true);
          }}
        >
          <i className="bi bi-plus"></i> Add New Factor
        </Button>
      </div>

      <div className="box-container tabs-icon">
        {tabs?.length > 0 ? (
          tabs?.map((d, i) => (
            <div
              onClick={() => navigate(`/table/${d?._id}`)}
              className="box box1 position-relative"
            >
              <div className="text">
                <div className="topic-innercontent">
                  <div className="d-flex">
                    <div>
                      <span className="topic-heading">{d?.title}</span>
                      <span className="topic-desc">{d?.description}</span>
                    </div>
                    {d?.title.includes("Country") ||
                    d?.title.includes("country") ? null : (
                      <TbEdit
                        onClick={(e) => {
                          e.stopPropagation();
                          editdata(d, i);
                        }}
                        style={{
                          textAlign: "right",
                          width: "45px",
                          height: "20px",
                        }}
                      />
                    )}

                    {/* <Dropdown style={{ zIndex: 100 }} onClick={(e) => e.stopPropagation()}>
                      <Dropdown.Toggle
                        variant="light"
                        id="dropdown-basic"
                        className="p-0 bg-transparent border-0 position-absolute"
                        // Prevent event propagation to the parent div
                      >
                        <i className="bi bi-three-dots-vertical text-black"></i>
                      </Dropdown.Toggle>

                      <Dropdown.Menu style={{ zIndex: 100 }}>
                        <Dropdown.Item onClick={() => editdata(d, i)}>
                          <small>Edit</small>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown> */}
                  </div>

                  <div className="text-end">
                    <span className="weight-heading">Weight: </span>{" "}
                    <span className="weight-value">{d?.weight}</span>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div
            style={{ height: "500px" }}
            className="d-flex justify-content-center align-items-center"
          >
            <BeatLoader color="#0E8DEE" />
          </div>
        )}
      </div>

      <AddDetailModalComp
        tabs={tabs}
        show={detailModal}
        onHide={() => setDetailModal(false)}
        Riskdata={selectedTab}
      />
    </>
  );
};

export default DashBoard;
