import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { fetchKycDetailRequest } from "./store/actions/kycActions";
import { calculateRisk } from "./store/actions/riskTableActions";
import { GetTabs } from "./store/actions/tabsActions";
import { GetCountriesScores } from "./store/actions/scoreActions";
import Loader from "./Loader";

const DetailsNew = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setisLoading] = useState(true);
  const { pathname } = location;
  const { kycDetail, loading, error } = useSelector((state) => state.kyc);
  const { user, company } = useSelector((state) => state.user);
  const { countriesScores } = useSelector((state) => state.score);
  const kycMapping = {
    "Risk Level for Identity": "idType1",
    "Risk Level for ID Verification": "idType2",
    "Risk Level for Proof of Address": "proofOfAddressType",
    "Risk Level for Source of Income Proof": "proofOfIncomeType",
  };

  const [selectedOptions, setSelectedOptions] = useState([]);
  let kycId; // Declare kycId with let or const
  let JwtToken; // Declare JwtToken with let or const

  useEffect(() => {
    if (pathname.includes("/details/")) {
      // window.location.reload()
      const afterDetails = pathname.split("/details/")[1].split("&")[0];
      if (afterDetails) {
        const parts = afterDetails.split("/");
        if (parts.length > 1) {
          kycId = parts[0];
          JwtToken = parts[1];
          localStorage.setItem("token", parts[1]);
          localStorage.setItem("kycID", parts[0]);
        } else {
          console.log("Invalid pathname format.");
        }
      } else {
        console.log("No content after '/details/'.");
      }
    } else {
      setisLoading(false);
      console.log("No '/details/' in pathname.");
    }
  }, []);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      dispatch({ type: "LOAD_USER_REQUEST" }); // Trigger the saga to fetch user data
    }
  }, [dispatch]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const kycId = localStorage.getItem("kycID");
    if (!kycDetail && token) {
      dispatch(fetchKycDetailRequest(kycId));
    }
  }, [dispatch, kycDetail]);

  const moveToDashboard = () => {
    navigate("/dashboard");
  };

  console.log("kycDetail", kycDetail);

  // my code

  const { tabs } = useSelector((state) => state.tab);

  useEffect(() => {
    if (company && !tabs) {
      if (company && !company?.riskAccess) {
        navigate("/forbidden");
      } else {
        dispatch(GetTabs(company?._id, "kyc"));
      }
    }
  }, [user, company]);

  useEffect(() => {
    if (!countriesScores && company) {
      dispatch(GetCountriesScores(company?._id));
    }
  }, [dispatch, countriesScores, company]);
  console.log("countriesScores", countriesScores);

  const extractedArray = Object.entries(selectedOptions).map(
    ([key, option]) => ({
      name: key,
      rating: option.rating,
    })
  );

  const handleSubmit = async () => {
    let requestedBody = {
      riskLevels: data,
    };
    console.log("requestedBody", requestedBody);
    // e.preventDefault(); // Prevent form submission
    await dispatch(
      calculateRisk(
        requestedBody,
        kycDetail?._id,
        kycDetail?.companyID,
        navigate,
        "kyc"
      )
    );
  };

  const [data, setData] = useState([]);

  //Handle Change

  const handleSelect = (event) => {
    let { name, value, key } = event.target;
    //  console.log(event.target.options[event.target.selectedIndex].getAttribute('key'))
    const selectedOption = event.target.options[event.target.selectedIndex];
    const defaultFild = selectedOption.dataset.default;
    const TitleFild = selectedOption.dataset.title;
    const WeightFild = selectedOption.dataset.weight;

    setData((prevData) => {
      // Ensure prevData is an array
      if (!Array.isArray(prevData)) {
        console.error("Previous state is not an array:", prevData);
        return [];
      }

      // Check if the name already exists in the data array
      const existingIndex = prevData.findIndex((item) => item.name === name);

      if (existingIndex !== -1) {
        // Update the existing entry
        return prevData.map((item, index) =>
          index === existingIndex
            ? {
                ...item,
                title: TitleFild,
                weight: WeightFild,
                DataSource: defaultFild === "true" ? "Default" : "Manual",
                rating: Number(value),
              }
            : item
        );
      } else {
        // Add a new entry to the data array
        return [
          ...prevData,
          {
            name,
            title: TitleFild,
            weight: WeightFild,
            DataSource: defaultFild === "true" ? "Default" : "Manual",
            rating: Number(value),
          },
        ];
      }
    });
  };

  const handleAutoSelect = (event) => {
    let { name, value, key, weight, isDefault } = event.target;

    setData((prevData) => {
      // Ensure prevData is an array
      if (!Array.isArray(prevData)) {
        console.error("Previous state is not an array:", prevData);
        return [];
      }

      // Check if the name already exists in the data array
      const existingIndex = prevData.findIndex((item) => item.name === name);

      if (existingIndex !== -1) {
        // Update the existing entry
        return prevData.map((item, index) =>
          index === existingIndex
            ? {
                ...item,
                title: key,
                weight,
                DataSource: isDefault ? "Default" : "Manual",
                rating: Number(value),
              }
            : item
        );
      } else {
        // Add a new entry to the data array
        return [
          ...prevData,
          {
            name,
            title: key,
            weight,
            DataSource: isDefault ? "Default" : "Manual",
            rating: Number(value),
          },
        ];
      }
    });
  };

  function findCategory(title, countryName) {
    console.log(title, countryName);
    if (countriesScores) {
      for (const item of countriesScores) {
        const field = item?.fields?.find(
          (field) => field?.name === countryName
        );
        if (field) {
          return field.category;
        }
      }
      return null; // Return null if the title or country is not found
    }
  }

  function findFieldByString(searchString) {
    const keywords = {
      "Co-operative": "Cooperative Countries",
      "Non-Cooperative": "Non-Cooperative Country/ML Jurisdictions",
      "Tax Haven": "Tax Haven countries which support terrorist activities",
      "Trade Sanctions": "Countries subject to Economic / Trade Sanctions",
      "Identified by FATF":
        "Countries identified by FATF as not having AML&CFT system",
      Corruption: "Countries on Corruption and Perception Index",
    };

    const matchedTitle = keywords[searchString];

    if (!matchedTitle) {
      return null;
    }

    return matchedTitle;
  }

  function getHighestScoreObject(array) {
    // Return null if array is empty or invalid
    if (!array || array.length === 0) return null;

    // Use reduce to find object with highest score
    return array.reduce((max, current) => {
      return current.score > max.score ? current : max;
    }, array[0]);
  }

  useEffect(() => {
    if (kycDetail && countriesScores && tabs && tabs.length > 0) {
      tabs.forEach((tab) => {
        const handleSelection = (option) => {
          handleAutoSelect({
            target: {
              name: tab.title,
              key: option.title,
              weight: tab.weight,
              value: option.score,
              isDefault: option.default,
            },
          });
        };

        const findAndHandleOption = (searchValue) => {
          const searchString = findCategory(tab.title, searchValue);
          console.log("searchString", searchString);
          const optionName = findFieldByString(searchString);

          if (optionName) {
            const matchedOption = tab.scores[0]?.fields.find(
              (option) => option?.title === optionName
            );

            console.log(optionName);
            if (matchedOption) handleSelection(matchedOption);
            console.log(matchedOption);
          } else if (defaultOption) {
            handleSelection(defaultOption);
          }
        };
        const defaultOption = tab.scores[0]?.fields.find(
          (option) => option.default
        );

        switch (tab.title) {
          case "Country of Residence":
            findAndHandleOption(kycDetail?.countryOfResidence);
            break;
          case "Country of Nationality":
            findAndHandleOption(kycDetail?.countryOfBirth);
            break;
          case "Country of Operation":
            findAndHandleOption(kycDetail?.countryOfResidence);
            break;
          case "Country of Jurisdiction":
            let ScoresObj = [];
            kycDetail?.jurisdictionComingFrom?.map((item) => {
              const searchString = findCategory(tab.title, item);

              const optionName = findFieldByString(searchString);

              if (optionName) {
                const matchedOption = tab.scores[0]?.fields.find(
                  (option) => option?.title === optionName
                );
                if (matchedOption) {
                  ScoresObj.push(matchedOption);
                }
              }
            });
            const mostHighesJurisdicationObj = getHighestScoreObject(ScoresObj);

            const matchedOption1 = tab.scores[0]?.fields.find(
              (option) => option?.title === mostHighesJurisdicationObj?.title
            );
            if (matchedOption1) {
              handleSelection(matchedOption1);
            } else {
              handleSelection(defaultOption);
            }
            break;

          case "Source of Income Legal Structure":
            const matchedOption = tab.scores[0]?.fields.find(
              (option) =>
                option?.title === kycDetail?.SourceOfIncomeLegalStructure?.title
            );
            if (matchedOption) {
              handleSelection(matchedOption);
            } else if (defaultOption) {
              handleSelection(defaultOption);
            }
            break;
          case "Other Sources of Wealth":
            const mostHighesWealthofIncomeObj = getHighestScoreObject(
              kycDetail?.OtherSourcesOfIncome
            );
            const matchedOption2 = tab.scores[0]?.fields.find(
              (option) => option?.title === mostHighesWealthofIncomeObj?.title
            );
            if (matchedOption2) {
              handleSelection(matchedOption2);
            } else if (defaultOption) {
              handleSelection(defaultOption);
            }
            break;
          case "Other Source of Income":
            const mostHighesSourceofIncomeObj = getHighestScoreObject(
              kycDetail?.OtherSourcesOfIncome
            );
            const matchedOption3 = tab.scores[0]?.fields.find(
              (option) => option?.title === mostHighesSourceofIncomeObj?.title
            );
            if (matchedOption3) {
              handleSelection(matchedOption3);
            } else if (defaultOption) {
              handleSelection(defaultOption);
            }
            break;
          case "Product and Service Types":
            const mostHighestServiceObj = getHighestScoreObject(
              kycDetail?.ProductType
            );
            const matchedOption4 = tab.scores[0]?.fields.find(
              (option) => option?.title === mostHighestServiceObj?.title
            );
            if (matchedOption4) {
              handleSelection(matchedOption4);
            } else if (defaultOption) {
              handleSelection(defaultOption);
            }
            break;
          case "Purpose of Account":
            const matchedOption5 = tab.scores[0]?.fields.find(
              (option) => option?.title === kycDetail?.PurposeOfAccount?.title
            );
            if (matchedOption5) {
              handleSelection(matchedOption5);
            } else if (defaultOption) {
              handleSelection(defaultOption);
            }
            break;
          case "Account Holder Types":
            const matchedOption6 = tab.scores[0]?.fields.find(
              (option) => option?.title === kycDetail?.AccountHolderType?.title
            );
            if (matchedOption6) {
              handleSelection(matchedOption6);
            } else if (defaultOption) {
              handleSelection(defaultOption);
            }
            break;
          case "Source of Income from Industry":
            const matchedOption7 = tab.scores[0]?.fields.find(
              (option) =>
                option?.title === kycDetail?.SourceOfIncomeFromIndustry?.title
            );
            if (matchedOption7) {
              handleSelection(matchedOption7);
            } else if (defaultOption) {
              handleSelection(defaultOption);
            }
            break;
          default:
            if (defaultOption) {
              handleSelection(defaultOption);
            } else {
              const kycKey = kycMapping[tab.title];
              if (kycKey) {
                const selectedField = kycDetail?.[kycKey]
                  ? tab.scores[0].fields[0]
                  : tab.scores[0].fields[1];
                handleSelection(selectedField);
              }
            }
            break;
        }
      });
    }
  }, [tabs, countriesScores, kycDetail]);

  console.log(data);
  useEffect(() => {
    if (
      kycDetail &&
      data &&
      data.length > 0 &&
      pathname.includes("/details/")
    ) {
      handleSubmit();
    }
  }, [data, kycDetail]);

  // console.log(tabs)

  function checkKYCValue(title) {
    const kycMapping = {
      "Country of Nationality": "countryOfBirth",
      "Country of Residence": "countryOfResidence",
      "Country of Operation": "countryOfResidence",
      "Source of Income Legal Structure": "SourceOfIncomeLegalStructure.title",
      "Other Source of Income": "OtherSourcesOfIncome.title",
      "Source of Income from Industry": "SourceOfIncomeLegalStructure.title",
      "Product and Service Types": "ProductType.title",
      "Purpose of Account": "PurposeOfAccount.title",
      "Account Holder Types": "AccountHolderType.title",
      "Source of Income from Industry": "SourceOfIncomeFromIndustry.title",
      "Risk Level for Identity": "idType1",
      "Risk Level for ID Verification": "idType2",
      "Risk Level for Proof of Address": "proofOfAddressType",
      "Risk Level for Source of Income Proof": "proofOfIncomeType",
    };

    const kycKey = kycMapping[title];
    console.log(kycKey);
    return kycKey
      ? kycKey.split(".").reduce((obj, key) => obj?.[key], kycDetail)
      : "N/A";
  }

  function isTitleMatching(name, title) {
    // Iterate through each object in the array
    for (let item of data) {
      // Check if the title matches the search string
      if (item.name === name && item.title === title) {
        return true; // Return true if a match is found
      }
    }
    return false; // Return false if no match is found
  }
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="d-flex flex-column bg-light">
          {user?.accountType === "admin" ? (
            <div className="navbar">
              <div></div>
              <button
                className="btn btn-info text-white"
                onClick={moveToDashboard}
              >
                Engine Settings <span>&gt;</span>
              </button>
            </div>
          ) : null}

          <div className="container ">
            <div className="text-center py-5">
              <h3>{kycDetail?.fullName}</h3>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-8">
                <form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    let requestedBody = {
                      riskLevels: data,
                    };
                    // e.preventDefault(); // Prevent form submission
                    await dispatch(
                      calculateRisk(
                        requestedBody,
                        kycDetail?._id,
                        kycDetail?.companyID,
                        navigate,
                        "kyc"
                      )
                    );
                  }}
                >
                  {/* Country Operation */}
                  {tabs &&
                    tabs.map((d, i) => (
                      <div className="card border-primary mb-4 p-1 rounded-3">
                        <div className="bg-secondary-subtle card-header">
                          <h4 className="mb-2 text-primary">{d.title}</h4>
                        </div>
                        <div className="card-body rounded-bottom-3 bg-light-blue">
                          <div className="row">
                            <label className="col-sm-5 col-form-label">
                              {checkKYCValue(d.title)}
                            </label>
                            <div className="col-sm-7">
                              <Form.Select
                                aria-label="Default select example"
                                name={d.title}
                                // value={countryOperation.id || ""}
                                onChange={handleSelect}
                              >
                                <option>Open this select menu</option>
                                {d?.scores[0]?.fields?.map((fieldOptions) => (
                                  <option
                                    selected={isTitleMatching(
                                      d.title,
                                      fieldOptions.title
                                    )}
                                    key={fieldOptions.title}
                                    value={fieldOptions.score}
                                    data-default={fieldOptions.default}
                                    data-title={fieldOptions.title}
                                    data-weight={d.weight}
                                  >
                                    {fieldOptions.title}
                                  </option>
                                ))}
                              </Form.Select>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                  {/* Submit Button */}
                  <div className="text-center">
                    <Button variant="primary" type="submit">
                      Calculate Risk
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DetailsNew;
